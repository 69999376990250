import { reactive, defineComponent } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
    name: "EngineerRegister",
    setup: function () {
        var router = useRouter();
        var state = reactive({
            listBreadcrumb: ["Home", "ログイン・会員登録"]
        });
        function fnAcceptPolicy() {
            state.listBreadcrumb = [
                "Home",
                "ログイン・会員登録",
                " ポートフォリオ基本情報"
            ];
            router.push("/user/register/profile");
        }
        return {
            state: state,
            fnAcceptPolicy: fnAcceptPolicy
        };
    }
});
